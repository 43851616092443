import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueCurrencyFilter from 'vue-currency-filter'
import DatePicker from 'vue2-datepicker';

import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)

Vue.use(VueCurrencyFilter, [
  {
  name: 'currency',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
  },
  {
  name: 'currency2',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
  }
])

import 'vue2-datepicker/index.css';
Vue.component('DatePicker', DatePicker)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
