<template>
  <v-app>
    <keep-alive>
    <router-view></router-view>
</keep-alive>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600&display=swap');
* {
  font-family: 'Kanit', sans-serif;
}
.v-application--wrap {
  background: #F0F2F5;
  position: relative;
  overflow: scroll;
}
/* .v-content__wrap {
  overflow: scroll;
  background: #F0F2F5 !important;
} */
.v-card {
  border-radius: 3px !important;
}
.v-menu__content {
    max-height: 200px !important;
}
/* .v-autocomplete__content.v-menu__content {
    height: 100px !important;
} */
.v-expansion-panel-content__wrap {
    padding: 0px 1px 14px 0px !important;
}
</style>
