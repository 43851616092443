import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('../views/Verify.vue')
  },
  {
    path: '/shopping-goods',
    name: 'ShoppingGoods',
    component: () => import('../views/ShoppingGoods.vue')
  },
  {
    path: '/goods-detail',
    name: 'GoodsDetail',
    component: () => import('../views/GoodsDetail.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/order-list',
    name: 'OrderList',
    component: () => import('../views/OrderList.vue')
  },
  {
    path: '/receive',
    name: 'Receive',
    component: () => import('../views/Receive.vue')
  },
  {
    path: '/order-list-supplier',
    name: 'OrderListSupplier',
    component: () => import('../views/OrderListSupplier.vue')
  },
  {
    path: '/order-detail',
    name: 'OrderDetail',
    component: () => import('../views/OrderDetail.vue')
  },
  {
    path: '/goods-receipt',
    name: 'GoodsReceipt',
    component: () => import('../views/GoodsReceipt.vue')
  },
  {
    path: '/upload-proof',
    name: 'UploadProof',
    component: () => import('../views/UploadProof.vue')
  },
  {
    path: '/get-line-uid',
    name: 'getLineUid',
    component: () => import('../views/getLineUid.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// axios.defaults.baseURL =  "http://localhost:8080/fastway";
axios.defaults.baseURL =  "https://pos.api.rinen.dev/fastway";

export default router

