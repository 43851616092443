import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    line_uid: null,
    shoppingCart: [],
    goodsDetail: null,
    orderDetails:[],
    orderDetailsReceive: []
  },
  plugins: [createPersistedState({
    paths : ['line_uid','orderDetails','orderDetailsReceive','goodsDetail']
  })],
  mutations: {
    setLineUid(state, value) {
      state.line_uid = value
    },
    setShoppingCart(state,value) {
      state.shoppingCart.push(value)
    },
    setShoppingCartByIndex(state,{quantity,index,price,vat}) {
      state.shoppingCart[index].quantity += quantity
      state.shoppingCart[index].summary += parseFloat(quantity) * parseFloat(price)
      state.shoppingCart[index].summaryVat += (parseFloat(quantity * parseFloat(price))) + ((parseFloat(quantity * parseFloat(price))) * ( vat / 100 ))
    },
    editItemQuantity(state, {index,quantity,price,vat}) {
      state.shoppingCart[index].quantity = quantity
      state.shoppingCart[index].summary = parseFloat(quantity * parseFloat(price))
      state.shoppingCart[index].summaryVat = (parseFloat(quantity * parseFloat(price))) + ((parseFloat(quantity * parseFloat(price))) * ( vat / 100 ))
    },
    addItemQuantity(state, {index, price, vat}) {
      state.shoppingCart[index].quantity = state.shoppingCart[index].quantity + 0.1
      state.shoppingCart[index].quantity = state.shoppingCart[index].quantity.toFixed(1)
      state.shoppingCart[index].quantity = parseFloat(state.shoppingCart[index].quantity)
      state.shoppingCart[index].summary += parseFloat(price) * 0.1
      state.shoppingCart[index].summaryVat += parseFloat(price) * 0.1 + (parseFloat(price) * (vat / 100))
    },
    decItemQuantity(state, {index, price, vat}) {
      if (state.shoppingCart[index].quantity > 0.1) {
        state.shoppingCart[index].quantity = state.shoppingCart[index].quantity - 0.1
        state.shoppingCart[index].quantity = state.shoppingCart[index].quantity.toFixed(1)
        state.shoppingCart[index].quantity = parseFloat(state.shoppingCart[index].quantity)
        state.shoppingCart[index].summary -= parseFloat(price) * 0.1
        state.shoppingCart[index].summaryVat -= parseFloat(price) * 0.1 + (parseFloat(price) * (vat / 100))
      }
    },
    delItemQuantity(state, index) {
      state.shoppingCart.splice(index, 1)
    },
    clearItemShoppingCart(state) {
      state.shoppingCart = []
    },
    setOrderDetails(state, value) {
      state.orderDetails = value
    },
    updateOrderPR(state, value) {
      state.orderDetails = value
    },
    setQuantityOrderDetails(state, value) {
      console.log(value);
      state.orderDetails.goods[value.index].quantity = parseInt(value.quantity)
    },
    setApproveOrderSupplier(state) {
      state.orderDetails.purchaseRequisitionStatus = "APPROVED"
    },
    setCancelOrderSupplier(state) {
      state.orderDetails.purchaseRequisitionStatus = "REJECTED"
    },
    setDetailsReceive(state, value) {
      state.orderDetailsReceive = value
    },
    setGoodsDetail(state,value) {
      state.goodsDetail = value
    },
    addNoteGoods(state,value) {
      state.shoppingCart[value.index].note = value.input
    }
  },
  actions: {
    setLineUid({commit}, value) {
      commit('setLineUid', value)
    },
    setShoppingCart({commit}, value) {
      if (this.getters.getShoppingCart.length > 0) {
        let isSameItem = this.getters.getShoppingCart.some(item => item.id === value.id)
        if (isSameItem) {
          let index = this.getters.getShoppingCart.findIndex(x => x.id === value.id)
          let quantity = value.quantity
          let price = value.unitPrice
          let vat = value.vat
          console.log(value);
          commit('setShoppingCartByIndex', {quantity, index, price, vat})
        } else {
          commit('setShoppingCart', value)
        }
      } else {
        commit('setShoppingCart', value)
      }
    },
    addItemQuantity({commit}, value) {
      console.log(value);
      let index = this.getters.getShoppingCart.findIndex(x => x.id === value.id)
      let price = value.price
      let vat = value.vat
      commit('addItemQuantity', { index, price, vat})
    },
    decItemQuantity({commit}, value) {
      let index = this.getters.getShoppingCart.findIndex(x => x.id === value.id)
      let price = value.price      
      let vat = value.vat
      commit('decItemQuantity', { index, price, vat})
    },
    editItemQuantity({commit}, value) {
      let index = this.getters.getShoppingCart.findIndex(x => x.id === value.id)
      let quantity = value.quantity
      let price = value.price
      let vat = value.vat
      commit('editItemQuantity', {index, quantity, price, vat})
    },
    delItemQuantity({commit}, value) {
      let index = this.getters.getShoppingCart.findIndex(x => x.id === value)
      commit('delItemQuantity', index)
    },
    clearItemShoppingCart({commit}) {
      commit('clearItemShoppingCart')
    },
    setOrderDetails({ commit },value) {
      commit('setOrderDetails', value);
    },
    updateOrderPR({commit}, value) {
      commit('updateOrderPR', value)
    },
    setQuantityOrderDetails({commit}, value) {
      commit('setQuantityOrderDetails', value)
    },
    setApproveOrderSupplier({commit}) {
      commit('setApproveOrderSupplier')
    },
    setCancelOrderSupplier({commit}) {
      commit('setCancelOrderSupplier')
    },
    setDetailsReceive({commit}, value) {
      commit('setDetailsReceive', value)
    },
    setGoodsDetail({commit}, value) {
      commit('setGoodsDetail', value)
    },
    addNoteGoods({commit}, value) {
      commit('addNoteGoods', value)
    }
  },
  modules: {
  },
  getters: {
    getLineUid(state) {
      return state.line_uid
    },
    getShoppingCart(state) {
      return state.shoppingCart
    },
    getOrderDetails(state) {
      return state.orderDetails
    },
    getDetailsReceive(state) {
      return state.orderDetailsReceive
    },
    getGoodsDetail(state) {
      return state.goodsDetail
    }
  }
})
